<template>
    <div>
        <section class="section-common-info">
            <div class="container">
                <div class="container-s">
                    <div class="row row-wrap-blocks">
                        <div class="info-block">
                            <div class="icon icon-round"></div>
                            <div class="p">Current round</div>
                            <div class="h1">#{{ epoch + 1 }}</div>
                        </div>

                        <div class="info-block">
                            <div class="icon icon-limit"></div>
                            <div class="p">65% safety limit</div>
                            <div class="h1">
                                {{ box.currentPrincipalAmount ? parseFloat(Number(box.currentPrincipalAmount).toFixed(4)) : "0.00" }}
                                <span class="xs">BNB</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="section-other-info">
            <div class="container">
                <div class="row row-wrap-blocks">
                    <div class="info-block">
                        <div class="h1">{{ box.stopLoss === false ? "Profitable" : "Not profitable" }}</div>
                        <div class="p">Current status</div>
                    </div>
                    <div class="info-block">
                        <div class="h1">
                            {{ box.totalPositionAmount ? parseFloat(Number(box.totalPositionAmount).toFixed(4)) : "0.00" }} <span class="xs">BNB</span>
                        </div>
                        <div class="p">Cumulative # of seeds</div>
                    </div>
                    <div class="info-block">
                        <div class="h1">{{ box.totalPositionCount ? box.totalPositionCount : "0" }}</div>
                        <div class="p">Cumulative # of boxes</div>
                    </div>
                    <div class="info-block">
                        <div class="h1">
                            {{ box.currentIncentiveAmount ? parseFloat(Number(box.currentIncentiveAmount).toFixed(4)) : "0.00" }} <span class="xs">BNB</span>
                        </div>
                        <div class="p">Cumulative beneficial gold</div>
                    </div>

                    <div class="info-block">
                        <div class="h1">
                            {{ box.currentInvestAmount ? parseFloat(Number(box.currentInvestAmount).toFixed(4)) : "0.00" }} <span class="xs">BNB</span>
                        </div>
                        <div class="p">Current box 26%-growth amount</div>
                    </div>
                    <div class="info-block">
                        <div class="h1">
                            >{{ box.totalPositionAmount ? parseFloat(Number((box.totalPositionAmount * 26) / 100).toFixed(4)) : "0.00" }}
                            <span class="xs">BNB</span>
                        </div>
                        <div class="p">Cumulative box 26%-growth amount</div>
                    </div>
                    <div class="info-block">
                        <div class="h1">
                            {{ box.fundTarget && box.fundTarget.achievedAmount ? parseFloat(Number(box.fundTarget.achievedAmount).toFixed(4)) : "0.00" }}
                            <span class="xs">BNB</span>
                        </div>
                        <div class="p">Amount achieved</div>
                    </div>
                    <div class="info-block">
                        <div class="h1">
                            {{ box.fundTarget && box.fundTarget.achievedAmount ? parseFloat(Number(box.fundTarget.amount).toFixed(4)) : "0.00" }}
                            <span class="xs">BNB</span>
                        </div>
                        <div class="p">Target amount</div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    export default {
        props: ["box", "epoch"],
    };
</script>
