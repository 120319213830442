<template>
    <div class="magix-boxes">
        <section class="section-tabs">
            <div class="container">
                <TabsBox @boxSelected="selectedBox = $event" :selectedBox="selectedBox" />
            </div>
        </section>
        <section class="section-main-box">
            <CardComponent :selectedBoxProp="selectedBox" />
        </section>
        <BoxLevelStats :box="getBoxLevelToData" :epoch="getBoxToEpochs" />
    </div>
</template>

<script>
    import { mapState } from "vuex";
    import BoxLevelStats from "../../components/BoxLevelStats.vue";
    import CardComponent from "../../components/CardComponent.vue";
    import TabsBox from "../../components/TabsBox.vue";
    export default {
        name: "MagicBox",
        components: {
            CardComponent,
            TabsBox,
            BoxLevelStats,
        },
        data() {
            return {
                selectedBox: "0",
            };
        },
        computed: {
            ...mapState(["boxLevelToData", "boxToEpochs"]),
            getBoxLevelToData() {
                if (!this.boxLevelToData) return {};
                return this.boxLevelToData[this.selectedBox];
            },
            getBoxToEpochs() {
                if (!this.boxToEpochs) return 0;
                return this.boxToEpochs[this.selectedBox];
            },
        },
    };
</script>
