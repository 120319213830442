<template>
    <div class="tabs">
        <ul>
            <li v-for="(plan, id) in getPossiblePlans" 
                @mouseenter="position(plan.PLAN_ID)"
                :class="{ active: id === Number(selectedBox) }" 
                @click="$emit('boxSelected', plan.PLAN_ID)">
                <span
                    >{{ plan.PLAN_NAME }}
                    <i 
                        :ref="plan.PLAN_ID + '_ref_name'"
                        @mouseenter="objDetails[plan.PLAN_ID].PLAN_EVENT = true" 
                        @mouseleave="objDetails[plan.PLAN_ID].PLAN_EVENT = false">
                    </i>
                </span>
                <span
                    >{{ plan.PLAN_NAME }}
                    <i 
                        :ref="plan.PLAN_ID + '_ref_name'"
                        @mouseenter="objDetails[plan.PLAN_ID].PLAN_EVENT = true " 
                        @mouseleave="objDetails[plan.PLAN_ID].PLAN_EVENT = false">
                    </i>
                </span>
            </li>
        </ul>

        <template v-for="obj in objDetails">
            <div class="tabs-details" 
            v-show="obj.PLAN_EVENT == true"
            :style="
                'top:' +  obj.top  + 'px; left:' +  obj.left  + 'px;'
            "
            v-html="obj.PLAN_DESCRIBE"
            >
            </div>
        </template>
    </div>
</template>

<script>
    import { mapState } from "vuex";
    import config from "../../config.json";
    var conf = config[config.env];
    export default {
        props: ["selectedBox"],
        data() {
            return {
                objDetails: [
                    {
                        PLAN_EVENT: false,
                        PLAN_DESCRIBE:
                            "0 Opened magic boxes can be closed after 24 hours. When closed, the principal and profit seeds will be returned to the participant's address. When the current magic box is in the stop loss state, losing players will get 'Boost Credits' equal to their investment amount of this round. Players can spend these 'Boost Credits' in any magic box and get a 0.5% daily return boost.",
                    },
                    {
                        PLAN_EVENT: false,
                        PLAN_DESCRIBE:
                            "1 Opened magic boxes can be closed after 24 hours. When closed, the principal and profit seeds will be returned to the participant's address. When the current magic box is in the stop loss state, losing players will get 'Boost Credits' equal to their investment amount of this round. Players can spend these 'Boost Credits' in any magic box and get a 0.5% daily return boost.",
                    },
                    {
                        PLAN_EVENT: false,
                        PLAN_DESCRIBE:
                            "2 Opened magic boxes can be closed after 24 hours. When closed, the principal and profit seeds will be returned to the participant's address. When the current magic box is in the stop loss state, losing players will get 'Boost Credits' equal to their investment amount of this round. Players can spend these 'Boost Credits' in any magic box and get a 0.5% daily return boost.",
                    },
                    {
                        PLAN_EVENT: false,
                        PLAN_DESCRIBE:
                            "3 Opened magic boxes can be closed after 24 hours. When closed, the principal and profit seeds will be returned to the participant's address. When the current magic box is in the stop loss state, losing players will get 'Boost Credits' equal to their investment amount of this round. Players can spend these 'Boost Credits' in any magic box and get a 0.5% daily return boost.",
                    },
                    {
                        PLAN_EVENT: false,
                        PLAN_DESCRIBE:
                            "4 Opened magic boxes can be closed after 24 hours. When closed, the principal and profit seeds will be returned to the participant's address. When the current magic box is in the stop loss state, losing players will get 'Boost Credits' equal to their investment amount of this round. Players can spend these 'Boost Credits' in any magic box and get a 0.5% daily return boost.",
                    },
                    {
                        PLAN_EVENT: false,
                        PLAN_DESCRIBE:
                            "5 Opened magic boxes can be closed after 24 hours. When closed, the principal and profit seeds will be returned to the participant's address. When the current magic box is in the stop loss state, losing players will get 'Boost Credits' equal to their investment amount of this round. Players can spend these 'Boost Credits' in any magic box and get a 0.5% daily return boost.",
                    },
                ],
            };
        },
        computed: {
            ...mapState(["currentBlockchain", "preselectedChain"]),
            getPossiblePlans() {
                let chainId;
                if (!this.currentBlockchain) {
                    chainId = this.preselectedChain;
                } else {
                    chainId = this.currentBlockchain;
                }
                return conf[chainId].PLANS;
            },
        },
        methods:{
            position(a){
                let ref=+a+'_ref_name';
                let top, left ,obj;
                obj = this.objDetails;
                top =this.$refs[ref][0].getBoundingClientRect().top + 24;
                left =this.$refs[ref][0].getBoundingClientRect().left + 16;
                this.objDetails[a].top = top;
                this.objDetails[a].left = left;
            }
        }
    };
</script>
